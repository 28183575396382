@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";

// Checkboxes and radios
//
// Base class takes care of all the key behavioral aspects.

// Purple
$custom-control-purple-indicator-checked-bg: color("purple");
$custom-control-purple-indicator-checked-color: $component-active-color;
$custom-control-purple-indicator-checked-border-color: $custom-control-purple-indicator-checked-bg;
$custom-control-purple-indicator-focus-box-shadow: 0 0 0 $input-btn-focus-width rgba(color("purple"), .25) !default;
$custom-control-purple-indicator-focus-border-color: lighten(color("purple"), 25%) !default;
$custom-control-purple-indicator-active-color:         $component-active-color !default;
$custom-control-purple-indicator-active-bg:            lighten(color("purple"), 35%) !default;
$custom-control-purple-indicator-active-border-color:  $custom-control-purple-indicator-active-bg !default;

.custom-control.custom-control-purple .custom-control-input {
  &:checked ~ .custom-control-label::before {
    color: $custom-control-purple-indicator-checked-color;
    border-color: $custom-control-purple-indicator-checked-border-color;
    @include gradient-bg($custom-control-purple-indicator-checked-bg);
  }

  &:focus ~ .custom-control-label::before {
    box-shadow: $custom-control-purple-indicator-focus-box-shadow;
  }

  &:focus:not(:checked) ~ .custom-control-label::before {
    border-color: $custom-control-purple-indicator-focus-border-color;
  }

  &:not(:disabled):active ~ .custom-control-label::before {
    color: $custom-control-purple-indicator-active-color;
    background-color: $custom-control-purple-indicator-active-bg;
    border-color: $custom-control-purple-indicator-active-border-color;
  }
}

// Green
$custom-control-green-indicator-checked-bg: color("green");
$custom-control-green-indicator-checked-color: $component-active-color;
$custom-control-green-indicator-checked-border-color: $custom-control-green-indicator-checked-bg;
$custom-control-green-indicator-focus-box-shadow: 0 0 0 $input-btn-focus-width rgba(color("green"), .25) !default;
$custom-control-green-indicator-focus-border-color: lighten(color("green"), 25%) !default;
$custom-control-green-indicator-active-color:         $component-active-color !default;
$custom-control-green-indicator-active-bg:            lighten(color("green"), 35%) !default;
$custom-control-green-indicator-active-border-color:  $custom-control-green-indicator-active-bg !default;

.custom-control.custom-control-green .custom-control-input {
  &:checked ~ .custom-control-label::before {
    color: $custom-control-green-indicator-checked-color;
    border-color: $custom-control-green-indicator-checked-border-color;
    @include gradient-bg($custom-control-green-indicator-checked-bg);
  }

  &:focus ~ .custom-control-label::before {
    box-shadow: $custom-control-green-indicator-focus-box-shadow;
  }

  &:focus:not(:checked) ~ .custom-control-label::before {
    border-color: $custom-control-green-indicator-focus-border-color;
  }

  &:not(:disabled):active ~ .custom-control-label::before {
    color: $custom-control-green-indicator-active-color;
    background-color: $custom-control-green-indicator-active-bg;
    border-color: $custom-control-green-indicator-active-border-color;
  }
}
