.table {
  table-layout: fixed;
}

.idColumn {
  width: 150px;
  overflow: hidden;
}

.titleColumn {
  width: 300px;
}

.textColumn {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.actionsColumn {
  white-space: nowrap;
  width: 200px;
}
