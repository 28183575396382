.mainCategory {
  margin-bottom: 1.5rem;
  box-shadow: 1px 1px 2px rgba(0,0,0,0.06);
  border-radius: 4px;
}

.expandable {
  display: none;
}

.expanded {
  display: table-row;
}

.tableWrapper {
  border: 1px solid #dee2e6;
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: none;
}

.tableWrapper:global(.expanded) {
  display: block;
}

.table {
  table-layout: fixed;
  margin-bottom: 0;
}

.table tr:first-child td {
  border-top: none;
}

.header {
  padding: 0.75rem;
  background-color: #dee2e6;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  font-weight: bold;
}

.header:global(.expanded) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.header i {
  text-align: center;
  width: 0.75rem;
  margin-right: 0.5rem;
}

.categoryColumn {
  width: 18%;
}

.subcategoryColumn {
  width: 22%;
}

.countColumn {
  width: 3rem;
  cursor: pointer;
  text-align: right;
  user-select: none;
}

td.categoryColumn, td.subcategoryColumn {
  font-size: 12px;
}

td.categoryColumn, td.subcategoryColumn, td.countColumn, td.matchColumn {
  vertical-align: middle;
}

td.countColumn {
  padding-left: 0;
  padding-right: 0;
}

.matchColumn {
  //
}

.contextToggleColumn {
  width: 2.5rem;
  cursor: pointer;
}

.context {
  color: #808080;
}

.toggleIcon {
  color: #a8a8a8;
}

.contextToggleColumn:hover .toggleIcon {
  color: #000000;
}

.toggleIcon:global(.active) {
  color: #000000;
}

.moreExpander {
  color: #a8a8a8;
  white-space: nowrap;
}

.moreExpander i {
  width: 0.75rem;
}

.countColumn:hover .moreExpander i {
  color: #000000;
}

.ellipsisLabel {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.numbers {
  font-variant-numeric: tabular-nums;
}