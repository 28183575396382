@import "../../scss/rem-calc";
@import "../../scss/colors";

.aspectEditor {
  margin-bottom: rem-calc(10px);
  width: 100%;
}

.foco {
  display: flex;
  align-items: center;
}

.foco:focus {
  outline: none;
}

.cardHeader {
  display: flex;
  align-items: center;
  padding: rem-calc(6px 12px);
  line-height: 1;
  cursor: default;
  height: rem-calc(32px);
}

.cardHeader span {
  margin-right: rem-calc(20px);
}

.listItem {
  padding: rem-calc(0 12px);
}

.suggestion {
  display: flex;
  align-items: center;
  color: $purple;
  background-color: tint($purple, 95%);
  padding: rem-calc(0 12px 0 36px); // Liste item padding + checkbox width
  cursor: pointer;

  i {
    margin-left: 10px;
    opacity: 0.25;
  }

  &:hover i {
    opacity: 1;
  }

  :global(.basic-label) {
    opacity: 0.5;
    font-size: 75%;
    margin-left: auto;
    font-style: italic;
  }
}