.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #ffffff;
}

.content {
  align-items: baseline;

  div {
    vertical-align: middle;
  }

  h3 {
    display: inline;
    margin: 0;
    vertical-align: middle;
    margin-left: 10px;
  }
}