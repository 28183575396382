$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;

$primary: $blue;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

$body-color: $gray-900;

// Color blind safe colors taken from https://jfly.uni-koeln.de/color/
$cbs-black: #000000;
$cbs-orange: #E69F00;
$cbs-sky-blue: #56B4E9;
$cbs-blueish-green: #009E73;
$cbs-yellow: #F0E442;
$cbs-blue: #0072B2;
$cbs-vermillion: #D55E00;
$cbs-reddish-purple: #CC79A7;

@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

.text-cbs-black {
  color: $cbs-black;
}
.text-cbs-orange {
  color: $cbs-orange;
}
.text-cbs-sky-blue {
  color: $cbs-sky-blue;
}
.text-cbs-blueish-green {
  color: $cbs-blueish-green;
}
.text-cbs-yellow {
  color: $cbs-yellow;
}
.text-cbs-blue {
  color: $cbs-blue;
}
.text-cbs-vermillion {
  color: $cbs-vermillion;
}
.text-cbs-reddish-purple {
  color: $cbs-reddish-purple;
}
