.menu {
  background: linear-gradient(to bottom, #feffff 0%,#f2f2f2 100%);
  border-bottom: 1px solid #cccccc;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

$logoWidth: 145px;

.logoContainer {
  width: $logoWidth;
}

.logo {
  width: $logoWidth;
  height: 26px;

  background-image: url("../../images/360match_cockpit.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}