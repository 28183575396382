@import "../../scss/rem-calc";
@import "../../scss/columns";

.editor {
  width: 100%;
  min-width: 500px;

  @media (max-width: 1600px) {
    min-width: 450px;
  }

  @media (max-width: 1300px) {
    min-width: 400px;
  }

  @media (max-width: 1000px) {
    min-width: 350px;
  }
}

.horizontal {
  display: flex;
  flex-wrap: wrap;

  .editor {
    min-width: 0 !important;
  }

  &:global(.columns-1) .editor {
    @include n-per-row(1);
  }

  &:global(.columns-2) .editor {
    @include n-per-row(2);
  }

  &:global(.columns-3) .editor {
    @include n-per-row(3);
  }

  &:global(.columns-4) .editor {
    @include n-per-row(4);
  }

  &:global(.columns-5) .editor {
    @include n-per-row(5);
  }

  &:global(.columns-6) .editor {
    @include n-per-row(6);
  }
}