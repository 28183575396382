@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";

$custom-range-purple-thumb-bg: color("purple");
$custom-range-purple-thumb-active-bg: lighten(color("purple"), 35%);
$custom-range-purple-thumb-focus-box-shadow: 0 0 0 1px $body-bg, 0 0 0 $input-btn-focus-width rgba(color("purple"), .25);

.custom-range.custom-range-purple {
  &:focus {
    outline: none;

    // Pseudo-elements must be split across multiple rulesets to have an effect.
    // No box-shadow() mixin for focus accessibility.
    &::-webkit-slider-thumb { box-shadow: $custom-range-purple-thumb-focus-box-shadow; }
    &::-moz-range-thumb     { box-shadow: $custom-range-purple-thumb-focus-box-shadow; }
    &::-ms-thumb            { box-shadow: $custom-range-purple-thumb-focus-box-shadow; }
  }

  &::-webkit-slider-thumb {
    @include gradient-bg($custom-range-purple-thumb-bg);

    &:active {
      @include gradient-bg($custom-range-purple-thumb-active-bg);
    }
  }

  &::-moz-range-thumb {
    @include gradient-bg($custom-range-purple-thumb-bg);

    &:active {
      @include gradient-bg($custom-range-purple-thumb-active-bg);
    }
  }

  &::-ms-thumb {
    @include gradient-bg($custom-range-purple-thumb-bg);

    &:active {
      @include gradient-bg($custom-range-purple-thumb-active-bg);
    }
  }
}