$indent: 20px;
$inter-spacing: 1px;

%rule {
  font-size: 13px;
  padding: 1px;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.03);
  border-radius: 3px;
  margin-bottom: 2px;

  &:last-child {
    margin-bottom: 0;
  }

  &:global(.disabled) {
    > * {
      opacity: 0.5;
    }
  }
}

%comparisonSelect {
  margin-left: $inter-spacing;
  width: 150px;
}
