.main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.error {
  width: 400px;
  font-family: monospace;
}